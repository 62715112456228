<template>
  <v-app class="ss-user">
    <v-main class="ss-user__main">
      <v-toolbar
        flat
        color="rgba(240, 242, 245, 0.8)"
        class="align-items-center"
        style="
          position: sticky;
          top: 0;
          z-index: 1;
          backdrop-filter: blur(4px);
          border-bottom: 1px solid lightgrey;
        "
      >
        <v-app-bar-nav-icon
          class="hidden-md-and-up mr-6"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <v-row>
          <v-col class="d-flex align-items-center col-sm-6 col-12" v-if="currentNavItem">
            <v-icon class="mr-2">{{ currentNavItem.icon }}</v-icon
            >{{ currentNavItem.title }}
          </v-col>
          <v-col
            v-else-if="$route.name === 'UserProspectDetail'"
            class="d-flex align-items-center col-sm-6 col-12"
          >
            <v-icon class="mr-2">mdi-card-account-details</v-icon>Prospect Detail</v-col
          >
          <!--<v-col
            class="
              d-flex
              align-center
              justify-end
              col-sm-6 col-12
              text-sm-right
            "
          >
           <v-responsive max-width="300" class="mr-4">
              <v-text-field
                dense
                flat
                hide-details
                rounded-sm
                outlined
                clearable
                label="Search"
              ></v-text-field>
            </v-responsive>

            <v-btn icon x-small class="mr-4">
              <v-icon>mdi-cog</v-icon>
            </v-btn>

            <v-btn icon x-small class="mr-4">
              <v-icon>mdi-bell</v-icon>
            </v-btn> 
          </v-col>-->
        </v-row>
      </v-toolbar>
      <v-navigation-drawer
        app
        dark
        width="225"
        class="grey darken-4 ss-advisor-dash__nav-drawer"
        v-model="drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
      >
        <v-sheet class="pa-6 mb-8 grey darken-4 ss-advisor-dash__logo">
          <img src="@/assets/risk-score-logo-full-blue-white-darkbg.svg" />
        </v-sheet>

        <v-list dense nav>
          <template v-for="item in navItems">
            <v-divider
              :key="item.title"
              v-if="item.divider && !item.bottomNav"
              class="mb-4"
            ></v-divider>
            <a
              v-if="item.to === '/run-score-report'"
              :key="item.title"
              :href="$router.resolve('/run-score-report').href"
              >test</a
            >

            <v-list-item
              v-if="
                item.clickAction &&
                !item.bottomNav &&
                !item.divider &&
                item.to !== '/run-score-report'
              "
              :disabled="item.disabled"
              :exact="item.exact"
              :key="item.title"
              :ripple="false"
              :tag="item.tag"
              :class="item.classes"
              @click.prevent="handleItemClickAction(item.clickAction)"
              class="mb-4"
            >
              <v-list-item-icon class="mr-4">
                <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="!item.bottomNav && !item.divider"
              :disabled="item.disabled"
              :exact="item.exact"
              :key="item.title + 1"
              :ripple="false"
              :to="item.to"
              :href="item.href"
              :tag="item.tag"
              class="mb-4"
            >
              <v-list-item-icon class="mr-4">
                <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <div class="pa-4" style="position: absolute; bottom: 0; width: 100%">
          <v-list dense nav>
            <v-list-item
              :disabled="false"
              :exact="true"
              :ripple="false"
              to="/account"
              class="mb-4"
            >
              <v-list-item-icon class="mr-4 my-auto">
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <div class="mb-1">Manage Account</div>
                  <template v-if="currentUser && currentUser && currentUser.email"
                    >{{ currentUser.email }}
                  </template>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <template v-for="item in navItems">
              <v-list-item
                v-if="item.bottomNav && !item.divider"
                :disabled="item.disabled"
                :exact="true"
                :key="item.title"
                :ripple="false"
                :tag="item.tag"
                :to="item.to"
                :class="item.classes"
                class="mb-4"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template> -->
          </v-list>

          <v-divider class="mb-4"></v-divider>
          <v-btn small text block color="grey lighten-1" @click="handleSignOut">
            <v-icon class="mr-2" small>mdi-logout</v-icon> Sign out</v-btn
          >
        </div>
      </v-navigation-drawer>
      <div class="px-2 px-md-6 pb-6 pt-8 container--fluid">
        <slot :user="currentUser" />
      </div>
    </v-main>

    <v-footer
      color="transparent"
      height="72"
      inset
      :style="$vuetify.breakpoint.mdAndUp ? 'padding-left: 250px' : ''"
    >
      <p class="mb-0">
        <small>© {{ currentYear }} PRIA Technologies, LLC. All rights reserved.</small>
      </p>
    </v-footer>

    <v-dialog
      v-model="showProspectInviteDialog"
      max-width="800"
      width="75%"
      @input="handleResetDialogOnClose"
    >
      <v-card>
        <v-card-title class="d-flex mb-4">
          <h3>Invite Prospect</h3>
          <v-btn
            icon
            @click="
              handleResetDialogOnClose(false);
              showProspectInviteDialog = false;
            "
            class="ml-auto"
          >
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <p>
            Invite a prospect to take the Risk Score Assessment. The prospect will receive
            an email with a link to take the Risk Score Assessment in the context of your
            account.
          </p>
          <!-- <p>Sorry this feature is only available in the Pro Plan.</p>
          <UpgradePlan /> -->
          <ProspectCreateForm
            :advisorId="advisorId"
            :origin="origin"
            :resetInviteForm="resetInviteForm"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCustomLinkDialog" max-width="800" width="75%">
      <v-card>
        <v-card-title class="d-flex mb-4">
          <h3>Custom Risk Score Assessment Link</h3>
          <v-btn icon @click="showCustomLinkDialog = false" class="ml-auto">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <p>
            Use this custom link to send to prospective customers that allows them to take
            the Risk Score Assessment. After they complete the assessment, they will show
            up as prospects under your account for you to follow up with.
          </p>
          <p>Sorry this feature is only available in the Pro Plan.</p>
          <UpgradePlan />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading && !currentUser" color="#091437" z-index="10000">
      <v-progress-circular indeterminate size="70"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";

import UpgradePlan from "@/components/UpgradePlan.vue";
import { mapGetters } from "vuex";

import ProspectCreateForm from "@/components/ProspectCreateForm.vue";

export default {
  components: {
    UpgradePlan,
    ProspectCreateForm,
  },
  data: () => ({
    drawer: null,
    loading: true,
    showProspectInviteDialog: false,
    showCustomLinkDialog: false,
    origin: "advisorDashboard",
    resetInviteForm: null,
    navItems: [
      {
        disabled: false,
        exact: null,
        title: "Dashboard",
        to: "/dashboard",
        tag: "",
        active: false,
        icon: "mdi-view-dashboard",
      },
      {
        disabled: false,
        exact: null,
        title: "Run Score Report",
        // to: "/run-score-report",
        href: "/run-score-report",
        tag: "",
        active: false,
        icon: "mdi-presentation",
      },
      {
        disabled: false,
        exact: null,
        title: "Resources",
        to: "/resources",
        tag: "",
        active: false,
        icon: "mdi-tools",
      },
      {
        title: "divider",
        divider: true,
      },
      // {
      //   disabled: false,
      //   exact: null,
      //   title: "Invite Prospect",
      //   to: "",
      //   tag: "",
      //   active: false,
      //   icon: "mdi-send",
      //   clickAction: "showProspectInviteDialog",
      //   allowPlans: ["Pro", "Premium"],
      // },
      // {
      //   disabled: false,
      //   exact: null,
      //   title: "Assessment Link",
      //   to: "",
      //   tag: "",
      //   active: false,
      //   icon: "mdi-link",
      //   clickAction: "showCustomLinkDialog",
      //   allowPlans: ["Pro", "Premium"],
      // },
      {
        disabled: false,
        bottomNav: true,
        exact: null,
        title: "Manage Account",
        to: "/account",
        tag: "",
        active: false,
        icon: "mdi-account",
      },
    ],
  }),

  computed: {
    ...mapGetters(["currentUser"]),
    currentYear() {
      return new Date().getFullYear();
    },
    currentNavItem() {
      let current;

      if (this.navItems && this.$route.path) {
        current = this.navItems.find((item) => {
          if (item) {
            return item.to === this.$route.path;
          }
        });
      }
      return current;
    },
    advisorId() {
      return this.currentUser?.id;
    },
  },
  methods: {
    handleSignOut(e) {
      e.preventDefault();
      Auth.signOut()
        .then(() => {
          // this.$router.push("/auth/advisor");
        })
        .catch((error) => {
          console.log("signOut Error", error);

          //this.signedIn = false
        });
    },
    handleItemClickAction(action) {
      this[action] = !this[action];
    },
    handleResetDialogOnClose(e) {
      if (!e) {
        this.resetInviteForm = true;
      }
    },
  },
};
</script>
<style lang="scss">
.ss-user {
  &.theme--light.v-application {
    background-color: #f0f2f5;
  }

  &__main {
    @media screen and (min-width: 1264px) {
      /* padding-left: 274px !important; */
    }
  }

  &__nav-drawer {
    max-width: 15.625rem !important;
    /* height: calc(100% - 2rem) !important; */
  }

  .theme--dark {
    .v-list-item--active,
    .v-list-item--active:hover {
      color: var(--v-primary-base);

      .v-list-item__title {
        font-weight: bold;
      }

      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
