<template>
  <v-card color="accent" dark>
    <v-card-title
      ><v-icon x-large class="mr-4">mdi-professional-hexagon</v-icon>Upgrade to
      Pro!</v-card-title
    >
    <v-card-text class="pza4">
      Upgrade to a Pro account to be able to invite prospects to take the assessment and
      to create a custom assessment link you can share to gain more
      customers.</v-card-text
    >
    <v-card-actions class="pa-4"
      ><v-btn color="white" outlined> Upgrade Now </v-btn></v-card-actions
    >
  </v-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
