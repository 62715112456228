import { API, graphqlOperation } from "aws-amplify";
// import { searchAdvisors } from "@/graphql/queries";
// import { customSearchAdvisorVanityNames } from "@/customGraphQL/customSearchAdvisorVanityNames";
import { customSearchProspects } from "@/customGraphQL/customSearchProspects";
import { customSearchAdvisors } from "@/customGraphQL/customSearchAdvisors";
import { customSearchOrganizations } from "@/customGraphQL/customSearchOrganizations";

export async function doSearch(queryContext, variables) {
  try {
    let result;
    if (queryContext === "prospects") {
      const response = await API.graphql(
        graphqlOperation(customSearchProspects, variables)
      );
      result = response.data.searchProspects;
    } else if (queryContext === "advisors") {
      const response = await API.graphql(
        graphqlOperation(customSearchAdvisors, variables)
      );
      result = response.data.searchAdvisors;
    } else if (queryContext === "organizations") {
      const response = await API.graphql(
        graphqlOperation(customSearchOrganizations, variables)
      );
      result = response.data.searchOrganizations;
    }

    return result;
  } catch (error) {
    return error;
  }
}

// async function doSearchAdvisorVanityName(variables) {
//   console.log("HERE IN DO SEARCH", variables);
//   try {
//     const result = await API.graphql(
//       graphqlOperation(customSearchAdvisorVanityNames, variables)
//     );

//     return result;
//   } catch (error) {
//     return error;
//   }
// }
